export default [
    {
        path: '/apps/margin-documents',
        name: 'apps-margin-documents',
        component: () => import('@/views/pages/MarginDocuments.vue'),
        meta: {
            pageTitle: 'Margen de Documentos',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Margen de Documentos',
                    active: true,
                },
            ],
        },
    },

]