import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedStore from 'vuex-persistedstate'
import * as CryptoJS from 'crypto-js'
import ecommerceStoreModule from '@/views/pages/e-commerce/eCommerceStoreModule'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authUser from './auth-user'
import routing from './routing'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedStore({
      storage: {
        getItem: key => {
          const value = localStorage.getItem(key)
          if (value) {
            const bytes = CryptoJS.AES.decrypt(value, 'masterkey')
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
            return decryptedData
          }
          return null
        },
        setItem: (key, value) => {
          const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), 'masterkey').toString()
          localStorage.setItem(key, encryptedData)
        },
        removeItem: key => {
          localStorage.removeItem(key)
        },
      },
    }),
  ],
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: authUser,
    routing: routing,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
