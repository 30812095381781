import axios from '@/libs/axios'

export default class Auth {
    
    /**
     * Function to login
     * @return Promise
     */
    login(formdata) {        
        return axios.post(`/Cuentas/Login`, formdata);
    }

    impersonate(formData) {
        return axios.post(`/Cuentas/impersonate`, formData);
    }

    wareHouses(idEmpresa) {
        return axios.get(`/Cuentas/ListarAlmacenes/${idEmpresa}`);        
    }

    getSetting(empresaId, sucursalId) {
        return axios.get(`/WebConfiguraciones/ObtenerConfiguraciones/${empresaId}/${sucursalId}`);
    }

    getSettingByCompanyId(empresaId) {
        return axios.get(`/WebConfiguraciones/ObtenerConfiguracionesPorEmpresa`);
    }

    /**
     * Function to logout
     * @return Promise
     */
    logout() {
        return axios.get(`/Cuentas/Logout`);
    }

    /**
     * Function to get sessions     
     * @returns Promise
     */
    getSessions() {
        return axios.get(`/Cuentas/GetSessions`);
    }

    // getCurrentPermissions() {
    //     return axios.get(`/Cuentas/GetCurrentPermissions`)
    // }

    revokeToken(userId, device) {
        return axios.get(`/Cuentas/RevokeToken/${userId}/${device}`);
    }
}
